import * as React from 'react';
import * as styles from './superautopets.module.scss';
import WorkExample from '../../../components/WorkExample';

//Components
import Seo from '../../../components/seo';

//Images
import superAutoPetsHeaderImage from '../../../images/work-example-headers/super-auto-pets.png';

const SuperAutoPets = ({location})=>{
    return(
        <WorkExample
            pageName={'superautopets'}
            cardColor={'#8f6c0a'}
            title={'Super Auto Pets'}
            subtitle={"Redesign of an indie game's promotional site focussed on matching the in-game visual style"}
            imageData={superAutoPetsHeaderImage}
            imageAlt={"Super Auto Pets"}
            links={[
                {text: "View the project", url: "https://adoring-hamilton-015d1c.netlify.app/", external: true},
                // {text: "View the code on Github", url: "https://github.com/dkolonay/super-auto-pets"},
                {text: "View the original site", url: "https://web.archive.org/web/20220215183320/https://teamwoodgames.com/", external: true}
            ]}
            location={location}
        >

        <Seo
            title={"Super Auto Pets"}
            description={"Redesign of an indie game's promotional site focussed on matching the in-game visual style"}
            />

            <h2>About the Project</h2>
            <p>Super Auto Pets is an indie game by a small game studio.  
            The company has a one page website for the game that provides links to versions of 
            the game for different platforms and a trailer video to advertise the game.  
            As a personal project, I decided to make an updated version of the site with 
            some added character and visual design that matches the UI of the game.</p>

            <h2>What I did</h2>
            <ul>
                <li>Created an updated version of the game's promotional site</li>
                <li>Designed custom animations that match the style of the game using CSS Keyframes</li>
                <li>Updated the visual design of the site to match that of the game itself</li>
            </ul>
        </WorkExample>
    )
}

export default SuperAutoPets;